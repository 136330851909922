import { DeployedFile, QBankData } from '@web/services/gql/graphql';

export enum QBankMediaType {
	Image = 'Image',
	Video = 'Video',
	Vector = 'Vector',
	Document = 'Document'
}

export type QBanBankMedia = Omit<QBankData, '__typename'
	| 'displayId'
	| 'metaData'
	| 'mediaUrl'
	| 'thumbnailUrl'
	| 'mediaType'
	| 'deployedFiles'
> & {
	altText?: string;
	deployedFiles: QBankMediaTemplate[];
};

export type QBankMediaTemplate = Omit<DeployedFile, '__typename'
	| 'filename'
	| 'imageTemplateId'
	| 'videoTemplateId'
	| 'created'
	| 'filesize'
>;

export type QBankMedia = QBankImageType | QBankVectorType | QBankVideoType | QBankDocumentType;

export type QBankImageType = QBanBankMedia & {
	type: QBankMediaType.Image;
};

export type QBankVectorType = QBanBankMedia & {
	type: QBankMediaType.Vector;
};

export type QBankVideoType = QBanBankMedia & {
	type: QBankMediaType.Video;
};

export type QBankDocumentType = Omit<QBankData, '__typename'
| 'displayId'
| 'metaData'
| 'thumbnailUrl'
| 'mediaType'
| 'deployedFiles'
> & {
	altText?: string;
	deployedFiles: QBankMediaTemplate[];
	type: QBankMediaType.Document;
};
